<template>
  <div class="app">
    <section class="announcement">
      <h2>公告栏</h2>
      <p>校友有任何需求？飞跃编辑部建立了校友对接群：814392960</p>
      <p>新上线暑研经验版块<span class="new-tag">new</span></p>
      <p>新上线交流板块，主要用于招生、暑研、实习机会发布和求助<span class="new-tag">new</span></p>
    </section>
    
    <section class="about">
<h1>关于我们</h1>
    <p>Hi～这里是 USTC 飞跃编辑部。</p>
    <p></p>
    <p>
      飞跃网站是我们升级飞跃手册的大胆尝试。以往的申请季中，多届学长学姐编写的飞跃手册和先前的 Adrain
      网站给予了我们大量申请策略的启发和能力技巧的提升。前辈们无私传授的经验不仅使我们更好地充实自我，也鼓舞着我们继续传承这一优良文化。为了能够更好地传递这些宝贵经验，飞跃编辑部策划以网站的方式收录留学同学的申请总结，提升学长学姐、学弟学妹们以下方面的体验：
    </p>
    <ul>
      <li>
        <p>
          <strong>了解申请过程的全貌</strong>：在 申请 Wiki
          版块，编辑部不同学院的同学通力合作，整理、归纳往年海量申请经验，编写了相对客观、全面的申请指导。我们希望可以在短时间内，给予正在考虑或进行留学申请的学弟学妹申请过程的全貌。在具体实践中，也可以获得各个步骤中细致的指导。同时，我们建立了学院分版，以期收录不同专业具有针对性的信息。
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          <strong>迅速获取所需信息</strong
          >：留学申请没有绝对评价体系和录取标准因而往往被认为很“玄学”、“随机”的事情。申请过程中的信息差往往使得一些同学找到了最适合自己的项目从而获得梦校录取，而一些具有绝对实力的同学却很遗憾地没有拿到满意的录取。其中的信息包括但不限于项目偏好，招生信息、暑研体验等。对于留学申请的不了解往往导致盲目内卷
          GPA，而忽略了对核心竞争力的提升（和美好丰富的大学生活）。因此，我们提供了筛选、搜索录取信息、申请者背景的功能，从而可以从海量的申请总结贴中迅速挖掘到合适的项目及其信息，并尽快了解到项目官网介绍背后的含义，并与相关学长学姐取得联系、进一步了解。期望能从巨大的不确定性中获得些许的确定性。
        </p>
      </li>
      <li>
        <p>
          <strong>为多样的选择提供案例</strong
          >：李诚老师说，内卷是价值观的趋同。留学申请不仅仅是一次升学考验，更是一次自我审视的机会。在申请文书中，几乎每一所学校都会提问：“你为什么要攻读这个学位？”，“你为什么要申请我们学校？”这不仅仅是在申请季中抓耳挠腮的问题，更应是在大学中不断交流、探索、体验、思考得出的答案。因此，我们邀请了有考研、考公、就业、创业等经历的同学分享心路历程、准备过程和技能要求。期待每一位学弟学妹都能找到心之所向。
        </p>
      </li>
      <li>
        <p>
          <strong>与学长学姐建立联系</strong
          >：如果把人生的时间线拉长，或许每一个人对于以前的选择会有截然不同的视角和看法。因此，我们在录取信息中提供了“最终去向”和“项目体验”的部分，并希望能够建立留学多年的校友与在校学弟学妹之间的桥梁。有经验的前辈不仅能在申请上为学弟学妹们给出具体的技术指导，也能为人生的选择规划提供丰富的信息来源与洞见。
        </p>
      </li>
      <li>
        <p>
          我们真诚希望飞跃网站可以帮助学弟学妹远离无良中介的坑蒙拐骗，更高效地获取相关信息并更好地利用科大的资源，对减少内卷做出微薄的贡献。
        </p>
      </li>
    </ul>
    <h2>正确打开方式</h2>
    <p>
      为了共同建立良好的、可持续的交流环境，飞跃网站赋予浏览的同学们的正确打开方式是：
    </p>
    <ul>
      <li>
        <p>
          <strong>不传播网站信息</strong
          >：飞跃网站中收录了许多毕业生们的宝贵经验，其中涉及许多隐私信息，如联系方式、申请结果、申请背景等。这些辛辛苦苦编写、收集、整理的信息很容易成为留学中介盈利的工具。希望校内同学能自觉不向外传播信息，珍惜来之不易的交流环境。一经发现，编辑部将采取强硬措施。
        </p>
      </li>
      <li>
        <p>
          <strong>拥有提问的智慧</strong
          >：在想要进一步了解相关信息时，可以直接在飞跃群中讨论或者私聊相关学长学姐。但在提问过程中应当保有提问的智慧、节约双方的时间。
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          <strong>辩证看待信息</strong
          >：每一位毕业生也仅仅经历过一次申请、了解一些项目，难以对个人背景和申请过程有绝对客观、全面的评价。比如张三认为他获得
          MIT 录取的原因是自己 GPA
          4.3，但实际可能是他和诺奖大佬吃了顿饭并获得了他的推荐。所以，对于网站中的信息都应辩证看待。我们尽可能在
          Wiki
          版块提供客观、全面的信息，也期待在不同的申请总结中看到争辩和讨论。希望对于
          Wiki
          版块内容有不同见解的同学与我们联系（flyers_ustc@163.com或在飞跃群中私戳我们），提出宝贵意见，从而不断提升
          Wiki 版块的全面性和客观性。
        </p>
      </li>
    </ul>
    <p></p>
  </section>
  <section class="contact">
    <a class="anchor" id="contact"></a>
    <h2>联系我们</h2>
    <p>如果对网站有任何疑问或需求，欢迎与我们积极取得联系！</p>
    <ul>
      <li><p>如果你对网站建设有任何想法，欢迎直接私戳群主。</p></li>
      <li>
        <p>
          如果你需要发布招生或招聘广告，请在飞跃群中直接发出并 at
          群主，我们将整理至飞跃网站中。
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          如果你对网站中的内容有疑问，可以直接在飞跃群中提出，与大群中的小伙伴直接讨论～对于有代表性的问题我们将整理在
          Wiki 的 FAQ (Frequently Asked Questions) 中。
        </p>
      </li>
      <li>
        <p>
          如果你发现了 Wiki 内容中的问题（如笔误、不完善的地方）可发邮件至
          flyers_ustc@163.com
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          如果你遇到了网站的 bug 或者任何建议，请与现任站长：家璇 Jase（QQ：735896780）直接联系～
        </p>
        <p>或发邮件至 flyers_ustc@163.com，我们将尽快解决。</p>
      </li>
    </ul>
  </section>
    <p></p>
    <p>以下是飞跃群群号，持续更新中：</p>
    <div v-if="username" class="group-list">
      <ul>
        <li><p>2021 届飞跃总群：180446308</p></li>
        <li><p>2022 届飞跃总群：1158255412</p></li>
        <li><p>2023 届飞跃总群：677034076</p></li>
        <li><p>2024 届飞跃总群：526966348</p></li>
        <li><p>2025 届飞跃总群：590043107</p></li>
        <li class="new-group">
          <p>2026 届飞跃总群： 772451534</p>
          <span class="new-tag">New</span>
        </li>
      </ul>
    </div>
    <div v-else class="welcome-group-alert">
      <i>
        请
        <router-link to="/login">
          <a href="/login">登录</a>
        </router-link>
        以查看飞跃群号
      </i>
    </div>
    <p></p>
    
    <section class="appreciation">
    <div class="appreciation">
      <h2>特别鸣谢</h2>
      <div class="founding-team">
        <span>Shujing</span>
        <span>Kuang</span>
        <span>Guangcanlan</span>
      </div>
      <div class="contributors">
        <span>Balabala</span>
        <span>Haowen</span>
        <span>Jiaxuan</span>
        <span>Junwei</span>
        <span>Zhengtao</span>
        <span>Zhidao</span>
        <span>Cheng</span>
        <span>Shijia</span>
        <span>Sijia</span>
      </div>
      <div class="organizations">
        <span>国合部</span>
        <span>硅谷校友会</span>
        <span>新创校友基金会</span>
        <span>卢征天教授</span>
        <span>Zach Smith教授</span>
      </div>
    </div>
  </section>

  <footer class="footer">
      <p>USTC飞跃编辑部</p>
      <p>于美丽的科大校园 & 硅谷</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  created() {
    this.hashTo();
  },
  beforeRouteUpdate(to, from, next) {
    this.hashTo();
    next();
  },
  computed: {
    username() {
      return this.$store.getters.name;
    },
  },
  methods: {
    hashTo() {
      if (location.hash) {
        setTimeout(
          "document.querySelector(location.hash).scrollIntoView(true)",
          100
        );
      }
    },
  },
};
</script>

<style scoped>
.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  background-color: #fff;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #222;
}

h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #222;
}

p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

li {
  font-size: 16px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.announcement {
  background-color: #f0f0f0;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.announcement h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.announcement p {
  font-size: 16px;
  margin-bottom: 15px;
}

.new-tag {
  background-color: #4CAF50;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 10px;
}

.about {
  margin-bottom: 60px;
}

.contact {
  margin-bottom: 60px;
}

.group-list {
  margin-bottom: 40px;
}

.welcome-group-alert {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 30px;
  color: #666;
}

.appreciation {
  background-color: #f8f8f8;
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 60px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.appreciation h2 {
  color: #222;
  margin-bottom: 40px;
}

.appreciation .founding-team,
.appreciation .contributors,
.appreciation .organizations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.appreciation .founding-team span,
.appreciation .contributors span,
.appreciation .organizations span {
  background-color: #fff;
  border-radius: 20px;
  padding: 12px 24px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.footer {
  text-align: center;
  margin-top: 60px;
  font-size: 14px;
  color: #888;
}
</style>